<template>
  <div
    class="TextInput"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >



    <label :for="name">{{ label }}
      <a href="javascript:void">
        <div class="tooltip_custom">
          <svg v-if="tooltip" xmlns="http://www.w3.org/2000/svg" width="21.569" height="21.569" viewBox="0 0 21.569 21.569">
            <path id="Icon_awesome-question-circle" data-name="Icon awesome-question-circle" d="M22.131,11.347A10.784,10.784,0,1,1,11.347.563,10.784,10.784,0,0,1,22.131,11.347ZM11.636,4.128A5.632,5.632,0,0,0,6.568,6.9a.523.523,0,0,0,.118.707L8.2,8.752A.522.522,0,0,0,8.92,8.66C9.7,7.674,10.229,7.1,11.412,7.1c.888,0,1.987.572,1.987,1.433,0,.651-.538.986-1.415,1.477-1.023.573-2.377,1.287-2.377,3.073v.174a.522.522,0,0,0,.522.522h2.435a.522.522,0,0,0,.522-.522V13.2c0-1.238,3.617-1.289,3.617-4.638C16.7,6.042,14.087,4.128,11.636,4.128Zm-.289,10.784a2,2,0,1,0,2,2A2,2,0,0,0,11.347,14.913Z" transform="translate(-0.563 -0.563)" fill="#70d9cc"/>
          </svg>
          <span class="tooltiptext">{{tooltip}}</span>
        </div>
      </a>


    </label>
    <textarea
      :name="name"
      :id="name"
      :type="type"
      :tooltip="tooltip"
      :value="inputValue"
      @input="handleChange"
      @blur="handleBlur"
    >
    </textarea>
    <p class="help-message" v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<script>
import { useField } from "vee-validate";

export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    successMessage: {
      type: String,
      default: "",
    },
  },
  setup(props) {

    var {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  mounted() {

  },
};
</script>



<style scoped>
.TextInput {
  position: relative;
  margin-bottom: calc(1em * 1.5);
  width: 100%;
}

label {
  display: block;
  margin-bottom: 4px;
  width: 100%;
}

textarea {
  border-radius: 30px;
  border: 2px solid transparent;
  padding: 15px 10px;
  outline: none;
  background-color: #f2f5f7;
  width: 100%;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

textarea:focus {
  border-color: var(--primary-color);
}

.help-message {
  position: absolute;
  bottom: calc(-1.5 * 1em);
  left: 0;
  margin: 0;
  font-size: 14px;
}

.TextInput.has-error textarea {
  background-color: var(--error-bg-color);
  color: var(--error-color);
}

.TextInput.has-error textarea:focus {
  border-color: var(--error-color);
}

.TextInput.has-error .help-message {
  color: var(--error-color);
}

.TextInput.success textarea:focus {
  border-color: var(--success-color);
}

.TextInput.success .help-message {
  color: var(--success-color);
}
</style>

