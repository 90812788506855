<template>
  <div>
      
      <!-- Active Competitions -->

      <section class="container">
            <h1 class="text-center mt-5 mb-5">Contact Us</h1>
      
            <div class="row">
              <div class="col">
                <Form @submit="onSubmit" :validation-schema="schema">
                  <TextInput
                    name="name"
                    type="text"
                    label="Full name *"
                  />
                  <TextInput
                    name="email"
                    type="email"
                    label="Your associated account e-mail *"
                  />
                  <TextArea
                    name="subject"
                    type="text"
                    label="Your Message *"
                  />

                  <button type="submit" class="btn btn-custom">Send Message</button>

                </Form>
              </div>
              <div class="pt-5 text-center pb-5">
                    <a href="https://www.facebook.com/CompetitionManiaLtd" target="_blank">
                      Facebook
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                        <path id="Icon_awesome-facebook" data-name="Icon awesome-facebook" d="M23.563,12.132a11.5,11.5,0,1,0-13.3,11.43V15.477H7.344V12.132h2.921V9.583c0-2.9,1.716-4.5,4.344-4.5a17.594,17.594,0,0,1,2.575.226V8.154h-1.45a1.667,1.667,0,0,0-1.874,1.807v2.172h3.189l-.51,3.345H13.859v8.085A11.553,11.553,0,0,0,23.563,12.132Z" transform="translate(-0.563 -0.563)"/>
                      </svg>
                    </a>
                    <a href="https://www.instagram.com/competitionmanialtd/" target="_blank">
                      Instagram
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                        <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M11.5,7.841a5.9,5.9,0,1,0,5.9,5.9A5.887,5.887,0,0,0,11.5,7.841Zm0,9.731a3.834,3.834,0,1,1,3.833-3.834A3.84,3.84,0,0,1,11.5,17.571ZM19.009,7.6a1.375,1.375,0,1,1-1.375-1.375A1.372,1.372,0,0,1,19.009,7.6Zm3.9,1.4a6.808,6.808,0,0,0-1.857-4.819,6.849,6.849,0,0,0-4.818-1.858c-1.9-.108-7.589-.108-9.487,0A6.839,6.839,0,0,0,1.933,4.171,6.83,6.83,0,0,0,.076,8.99c-.108,1.9-.108,7.591,0,9.489A6.807,6.807,0,0,0,1.933,23.3a6.858,6.858,0,0,0,4.818,1.858c1.9.108,7.589.108,9.487,0A6.8,6.8,0,0,0,21.056,23.3a6.852,6.852,0,0,0,1.857-4.819c.108-1.9.108-7.585,0-9.484ZM20.461,20.517A3.881,3.881,0,0,1,18.275,22.7c-1.514.6-5.105.462-6.778.462s-5.27.133-6.778-.462a3.881,3.881,0,0,1-2.186-2.186c-.6-1.514-.462-5.107-.462-6.78s-.133-5.271.462-6.78A3.881,3.881,0,0,1,4.719,4.772c1.514-.6,5.105-.462,6.778-.462s5.27-.133,6.778.462a3.881,3.881,0,0,1,2.186,2.186c.6,1.514.462,5.107.462,6.78S21.062,19.008,20.461,20.517Z" transform="translate(0.005 -2.238)"/>
                      </svg>
                    </a>
                    <a href="https://www.tiktok.com/@competitionmania" target="_blank">
                      TikTok
                      <svg xmlns="http://www.w3.org/2000/svg" width="18.791" height="21.629" viewBox="0 0 18.791 21.629">
                        <path id="Icon_simple-tiktok" data-name="Icon simple-tiktok" d="M12.238.018C13.418,0,14.59.009,15.761,0a5.613,5.613,0,0,0,1.577,3.758A6.356,6.356,0,0,0,21.16,5.371V9a9.646,9.646,0,0,1-3.785-.874,11.135,11.135,0,0,1-1.46-.838c-.009,2.632.009,5.263-.018,7.886a6.883,6.883,0,0,1-1.217,3.551A6.714,6.714,0,0,1,9.354,21.62a6.571,6.571,0,0,1-3.677-.928,6.8,6.8,0,0,1-3.289-5.146c-.018-.451-.027-.9-.009-1.343a6.785,6.785,0,0,1,7.868-6.02c.018,1.334-.036,2.668-.036,4a3.092,3.092,0,0,0-3.947,1.911,3.575,3.575,0,0,0-.126,1.451,3.067,3.067,0,0,0,3.154,2.586,3.028,3.028,0,0,0,2.5-1.451,2.079,2.079,0,0,0,.369-.955c.09-1.613.054-3.217.063-4.83.009-3.632-.009-7.255.018-10.878Z" transform="translate(-2.369)"/>
                      </svg>
                    </a>
              </div>
            </div>
      </section>



  </div>

  <Footer />

</template>

<script>

import Footer from '../components/global/Footer';
import { Form } from "vee-validate";
import * as Yup from "yup";
import TextInput from "../components/Form/TextInput";
import TextArea from "../components/Form/TextArea";

import Api from '../scripts/api'

export default {
    components: {
        Footer,
        Form,
        TextInput,
        TextArea,
    },
    setup() {
      var schema = Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        subject: Yup.string().required()
      });

      return {
        schema,
      };
    },
    props: {
      account: Object
    },
    methods: {
      onSubmit: function(values) {
        this.contact(values.name, values.email, values.subject);
      },
      contact: async function(name, email, message) {
        var result = await Api.contact(name, email, message);
        if(result.status == 422) return this.$toast.error('Please fill in the form.');
        this.$toast.success('Message has been sent to the site owner. You will receive a response through email.')
      }
    }
}
</script>
<style>

:root {
  --primary-color: #0071fe;
  --error-color: #f23648;
  --error-bg-color: #fddfe2;
  --success-color: #21a67a;
  --success-bg-color: #e0eee4;
}
.submit-btn {
  background: var(--primary-color);
  outline: none;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 10px 15px;
  display: block;
  width: 100%;
  border-radius: 7px;
  margin-top: 40px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.submit-btn:hover {
  transform: scale(1.1);
}
</style>